// Background colors array
//
// $color
// $type
//
// Usage: property: color('red', 'dark');
@function color($color, $type:base) {
    @if map-has-key($colors, $color) {
        $curr_color: map-get($colors, $color);

        @if map-has-key($curr_color, $type) {
            @return map-get($curr_color, $type);
        }

    }

    @warn "Unknown (#{$color} #{$type}) in $colors.";

    @return null;
}

// Background colors array
//
// $top-left
// $top-right
// $bottom-right
// $bottom-left
//
// Usage: @include border-radius(5px, 5px, 5px, 5px)
@mixin border-radius($top-left:3px, $top-right:3px, $bottom-right:3px, $bottom-left:3px) {
    -webkit-border-radius: $top-left $top-right $bottom-right $bottom-left;
    -moz-border-radius: $top-left $top-right $bottom-right $bottom-left;
    border-radius: $top-left $top-right $bottom-right $bottom-left;
}

// Box shadow
//
// $x
// $y
// $blur
// $spread
// $color
//
// Usage: @include shadow(1px, 5px, 10px, 0px, red)
@mixin box-shadow($x:0px, $y:3px, $blur:10px, $spread:0px, $color: rgba(color('black'), 0.2)) {
    -webkit-box-shadow: $x $y $blur $spread $color;
    -moz-box-shadow:    $x $y $blur $spread $color;
    box-shadow:         $x $y $blur $spread $color;
}

// Transition
//
// $blur
// $spread
// $color
//
// Usage: @include transition(background, 0.3s, linear)
@mixin transition($type, $time:0.3s, $effect:linear) {
    -webkit-transition: $type $time $effect;
    -moz-transition: $type $time $effect;
    -o-transition: $type $time $effect;
    -ms-transition: $type $time $effect;
    transition: $type $time $effect;
}

// Fade
//
// $type
// $time
//
// Usage: @include fade(in, 0.3s)
@mixin fade($type:out, $time:0.2s) {
    transition: visibility 0s, opacity $time linear;

    @if $type == in {
        visibility: visible;
        opacity: 1;
    }

    @if $type == out {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity $time linear;
    }

}

// Arrow
//
// $direction
// $color
// $width
//
// Usage: @include border-radius(bottom, #ffffff, 10px)
@mixin arrow($direction:top, $color: color('yellow'), $width: 8px, $align: right, $percentage: 15%) {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: -$width;
        #{$align}: $percentage;
        margin: 0 auto;
        width: 0;
        height: 0;

        @if $direction == top {
            border-left: $width solid transparent;
            border-right: $width solid transparent;
            border-bottom: $width solid $color;
            border-top: 0;
        }

        @if $direction == bottom {
            border-left: $width solid transparent;
            border-right: $width solid transparent;
            border-top: $width solid $color;
            border-bottom: 0;
        }

        @if $direction == left {
            border-top: $width solid transparent;
            border-bottom: $width solid transparent;
            border-right:$width solid $color;
            border-left: 0;
        }

        @if $direction == right {
            border-top: $width solid transparent;
            border-bottom: $width solid transparent;
            border-left: $width solid $color;
            border-right: 0;
        }

    }

}