.block-title {
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
	padding: 26px 0;
	position: relative;
	border: 1px solid color('gray', 'light');
	border-left: 0;

	&:before,
	&:after {
		content: '';
		position: absolute;
		left: -1000%;
		height: 100%;
		width: 1000%;
	}

	&:before {
		border-top: 1px solid color('gray', 'light');
		top: -1px;
	}

	&:after {
		bottom: -1px;
		border-bottom: 1px solid color('gray', 'light');
	}

}

.block-content {
	margin-bottom: 100px;

	li {
		position: relative;
		border: 1px solid color('gray', 'light');
		border-left: 0;
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
		padding: 20px 0;
		border-top: 0;

		a {
			color: color('blue');
		}

		&:after {
			content: '';
			position: absolute;
			left: -1000%;
			height: 100%;
			width: 1000%;
		}

		&:after {
			border-bottom: 1px solid color('gray', 'light');
			top: 1px;
		}

		&.current {
			background: color('blue', 'light');
			color: color('white');
			border-color: color('blue', 'light');

			a {
				color: color('white');
			}

			&:after {
				background: color('blue', 'light');
				border-color: color('blue', 'light');
			    top: 0;
			    height: 102%;
			}

		}

	}

}