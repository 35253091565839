@font-face {
    font-family: 'Myriad Pro';
    src:    url('../../../santuario/default/fonts/MyriadPro-Regular.eot');
    src:    url('../../../santuario/default/fonts/MyriadPro-Regular.eot?tfqnd3#iefix') format('embedded-opentype'),
        url('../../../santuario/default/fonts/MyriadPro-Regular.ttf') format('truetype'),
        url('../../../santuario/default/fonts/MyriadPro-Regular.woff') format('woff'),
        url('../../../santuario/default/fonts/MyriadPro-Regular.svg#Myriad Pro') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src:    url('../../../santuario/default/fonts/MyriadPro-Bold.eot');
    src:    url('../../../santuario/default/fonts/MyriadPro-Bold.eot?tfqnd3#iefix') format('embedded-opentype'),
        url('../../../santuario/default/fonts/MyriadPro-Bold.ttf') format('truetype'),
        url('../../../santuario/default/fonts/MyriadPro-Bold.woff') format('woff'),
        url('../../../santuario/default/fonts/MyriadPro-Bold.svg#Myriad Pro') format('svg');
    font-weight: bold;
    font-style: normal;
}