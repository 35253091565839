.god-with-us {

	.main-container {
    	@include outer-container(100%);
    	background: color('gray', 'light');

    	.container, .main {
    		@include span-columns(12);
    		padding: 0;
    	}

	}

	.page-title {
		@extend .no-display;
	}

	.std {
		@include outer-container(960px);
		margin-top: 40px;
		margin-bottom: 40px;
		background: color('white');
		text-align: center;
		padding: 30px;

		.sub-title {
			font-style: italic;

			.one {
				font-size: 38px;
				color: color('blue', 'dark');
			}
	
			.two {
				font-size: 58px;
				color: color('yellow', 'dark');					
			}

			.three {
				font-size: 85px;
				color: color('blue', 'dark');
				line-height: 70px;
			}

		}

		.god-slider {
			margin-top: 50px;

			.swiper-button-prev,
			.swiper-button-next {
				color: color('blue');
				border: 1px solid color('blue');
				background: color('white');

				&.swiper-button-disabled {
					display: none;
				}

			}

			.swiper-button-next {
				right: 0;
			}

			.swiper-button-prev {
				left: 0;
			}

			img {
				margin-bottom: 20px;
			}

			strong {
				font-family: $font-bebas;
				color: color('yellow', 'dark');					
				display: block;
				margin-bottom: 10px;
				font-size: 30px;
				text-transform: uppercase;
			}

			p {
				font-size: 17px;
				font-style: italic;
				line-height: 20px;

				strong {
					font-family: $font;
					color: initial;
					display: initial;
					margin: 0;
					font-size: initial;
					text-transform: initial;
				}

			}

		}

	}

	&.discount-table {

		.std {
    		background: color('gray', 'light');
    		text-align: left;
    		padding: 0;

    		table {
				border-collapse: separate;
				border-spacing: 15px;

    			tr {

    				td {
    					padding: 60px;
    					background: color('white');

						&.sub-title {
							text-align: center;

							strong {
								font-size: 46px;
								line-height: 50px;
								color: color('blue', 'dark');
							}

						}

						&.left {
							font-style: italic;
							padding: 60px 40px;

							strong {
								width: 205px;
								display: block;
								padding-bottom: 30px;
								font-size: 28px;
								color: color('yellow', 'dark');	
								line-height: 30px;				
							}

							p {
								font-size: 16px;
								line-height: 20px;
							}

						}

						&.box {
							position: relative;
							text-align: center;
							background: color('blue', 'dark');
							vertical-align: bottom;

							p {
								width: 180px;
								font-family: $font-bebas;
								font-size: 32px;
								text-transform: uppercase;
								color: color('white');
								padding-bottom: 40px;
							}

	    					a {
	    						position: absolute;
	    						bottom: 30px;
	    						left: 60px;
								width: 180px;
	    						padding: 10px 0;
	    						display: block;
	    						text-transform: uppercase;
	    						text-align: center;
	    						background: color('white');
	    					}

						}

    				}

    			}

    		}

		}

	}

}