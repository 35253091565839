.account-information {
	@include span-columns(12);
	margin: 0 0 200px 0;

	.change-password-button {

		label {
			padding: 15px 40px;

			&:before,
			&:after {
				display: none;
			}

		}

	}

	.buttons-set {
		@include span-columns(12);
		margin: 10px 0 0 0;
	}

}
