#payment_form_webjump_braspag_dc {
    @include span-columns(12);
    background: url('../images/bg-card.png') 0 130px no-repeat;
    position: relative;
    padding-bottom: 30px;
	
	&:before {
		content: '';
		position: absolute;
		left: -50px;
		top: 0;
		height: 100%;
		width: 1px;
		background: color('gray', 'light');
	}

    li {
        color: color('white');
        margin-right: 0;

        label {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        .input-box {
            margin-bottom: 10px;

            input {
                background: color('blue');
                border: 0;
                padding: 6px 10px;
                font-size: 14px;
                font-weight: bold;    
                color: color('white');                    
            }

            .validation-advice {
                position: absolute;
                right: 30px;
                bottom: -30px
            }
            
            input, select {

                &.validation-failed {
                    border: 1px solid color('red');
                }  
                                
            }

        }

        &.card-code {

			.input-box {
				margin-bottom: 0;
				border-bottom: 1px solid color('gray', 'light');

				&:last-child {
					border-bottom: 0;
				}

				label {
					margin: 20px 0;
					font-size: 18px;
					font-weight: normal;

					img {
						display: inline-block;
						margin-right: 10px;
						vertical-align: middle;
					}

				}

	            .validation-advice {
	                top: -10px;
	            }				

			}

        }

        &.card-number {
            margin-top: 60px;
            margin-left: 20px;

            input {
                width: 40%;
            }

        }

        &.card-name {
            @include span-columns(5);
            margin-left: 20px;
        }

        &.card-exp {
            @include span-columns(2.6);

            .v-fix {
                @include span-columns(4);
                background: color('blue');
                text-align: center;
                margin-right: 0;

                &:last-child {
                    @include span-columns(7);
                    float: right;
                }

            }

            select {
                background: color('blue');
                border: 0;
                padding: 6px 0 6px 6px;
                font-size: 14px;
                font-weight: bold;    
                color: color('white');   
                -webkit-appearance:none;
                -moz-appearance: none;

                &::-ms-expand {
                    display: none;
                }

            }

        }

        &.card-cvv {
            @include span-columns(2.1);
            float: right;
            margin-right: 45px !important;
        }

        &.card-installments {
            margin-top: 50px;

            label {
                font-size: initial;
                font-weight: initial;
                color: color('gray');
            }

        }

    }

}