.slider {
	@include outer-container();
	padding: 60px 0;
	margin-bottom: 60px;
}

.carousel {
	@include span-columns(9);

	.products-grid {
		margin-top: 20px;

		.item {
			position: relative;
			padding-bottom: 20px;
			margin: 0;
			min-height: 400px;
			@include transition(background, 0.2s);

			&:hover {
				@include box-shadow(0, 0, 0, 0);
			}

		}

		label {
			background: none;
			text-transform: none;
			font-size: 16px;
			font-weight: normal;
			padding: 0;

			&:before,
			&:after {
				@extend .no-display;
			}

		}

		.more {
			position: absolute;
			right: 0;
			top: 40%;
			font-size: 60px;
			font-weight: bold;
			color: color('blue', 'light');
		}

	}

}

.result {
	@include span-columns(3);
	float: right;
	margin-right: 0;
	text-align: center;
	line-height: 50px;
	padding-top: 80px;

	.info {
		font-size: 30px;
		text-transform: uppercase;
	}

	.price {
	    font-size: 30px;
	    font-weight: bold;
	    color: color('blue', 'light');		
	}

	.discount {
		font-size: 17px;
		font-weight: bold;
	}

	.button {
		font-size: 18px;
		width: 80%;
		margin: 0 auto;
		line-height: normal;

		span {
			font-size: 16px;
		}

	}

}
