.col-1, .col-2 {
	@include span-columns(6);
	line-height: 48px;
}

.count {
	display: inline-block;
	vertical-align: top;
}

.sort-by {

	label {
		text-align: right;
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
	}

	label, select {
		@include span-columns(6);
	}

}

.pages {
	text-align: center;
}

&.top {

	.pages {
		@extend .no-display;
	}

}

&.bottom {
	margin-bottom: 60px;

	.col-1,
	.sort-by {
		@extend .no-display;
	}

	.col-2 {
		@include span-columns(12);
	}

}