.cms-home {

	.main-container {
    	@include outer-container(100%);

    	.container, .main {
    		@include span-columns(12);
    		padding: 0;
    	}

	}

	.page-title {
		@extend .no-display;
	}

	.top-banners {
		@include span-columns(12);

		.swiper-button-prev {
			left: $gapp; 
		}

		.swiper-button-next {
			right: $gapp; 
		}

	}

	@import "components/home/bar.scss";
	@import "components/home/showcase.scss";
	@import "components/home/mini-banners.scss";
}