.gallery-container {
    @include span-columns(8.7);
    margin-right: 0;
    position: relative;

    .product-image {

        .gallery-image {
            width: 80%;
            margin: 0 auto;
        }

        .swiper-button-next,
        .swiper-button-prev {
            top: 50%;
            font-size: 24px;
        }

    }

    .more-views {
        width: 60%;
        margin: 20px auto;

        .swiper-slide {

            img {
                width: initial;
                padding: 5px;
                display: block;
                border: 1px solid rgba(color('gray'), 0.2);
            }

        }

    }

}