.error-msg,
.success-msg,
.notice-msg {
    @include span-columns(12);
    border: 1px solid;
    text-align: center;
    padding: 12px 0;
    margin: 5px 0;
    text-transform: uppercase;
}

.error-msg {
    background-color: rgba(color('red'), .1);
    border-color: color('red');
    color: color('red');
}

.success-msg {
    background-color: rgba(color('green'), .1);
    border-color: color('green');
    color: color('green');
}

.notice-msg {
    background-color: rgba(color('yellow'), .1);
    border-color: color('yellow');
    color: color('yellow');
}

.messages {
    @include span-columns(12);
}
