.mini-banners {
	@include span-columns(12);
	background: color('gray', 'light');

	ul {
		@include outer-container;
		padding: 40px $gapp;

		li {
		    @include span-columns(6);
		    @include omega(2n);

		    img {
		    	width: 100%;
		    }

		}

	}

}