.price-label,
.map-link,
.minimal-price-link {
    @extend .no-display;
}

.price {
    font-size: 24px;
    font-weight: bold;
    color: color('blue', 'light');
}

.old-price {

    .price-label,
    .price {
        font-size: 14px;
        font-weight: normal;
        color: initial;
        text-decoration: line-through;
        margin-bottom: 5px;
        display: inline-block;
    }

}
