a, p {
	padding: 20px 0;
	text-align: center;
	font-size: 16px;
	text-transform: uppercase;
	@include span-columns(6, block-collapse);
}

span {
	margin-right: 5px;
	font-size: 14px;
    display: inline-block;
    vertical-align: top;	

    &.icon-eye {
    	font-size: 16px;
    }

}

.spy {
	background: color('black');
	color: color('white');

	&.full {
		@include span-columns(12);
	}

    &:hover {
		background: lighten(color('black'), 10);
    }

}

.add-to-cart {
	background: color('green');
	color: color('white');

    &:hover {
		background: darken(color('green'), 5);
    }

}

.out-of-stock {
	float: none;
	margin: 0 auto;
}
