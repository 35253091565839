.container {
	@include outer-container;
	padding: 15px $gapp;

	address {
		@include span-columns(10);
		font-size: 10px;
		line-height: 20px;
	}

	a {
		@include span-columns(2);
		text-align: right;
		margin-right: 0;

		img {
			margin-left: auto;
			margin-top: 8px;
		}

	}

}