$black: (
    "base" : #000000
);

$white: (
    "base" : #ffffff
);

$blue: (
    "light": #006eca,
    "base" : #004782,
    "dark" : #264370
);

$yellow: (
    "base" : #fcc100,
    "dark" : #b2830c
);

$gray: (
    "light"     : #f4f4f4,
    "base"      : #767676,
    "semi-dark" : #989898,
    "dark"      : #383838,
);

$green: (
    "base" : #48b80b
);

$red: (
    "base" : #f9061a
);

$colors: (
    "black" : $black,
    "white" : $white,
    "blue"  : $blue,
    "yellow": $yellow,
    "gray"  : $gray,
    "green" : $green,
    "red"   : $red
);

