dl {
	@include span-columns(10.5);
	margin-right: 0;

	dt {
		text-transform: uppercase;
		margin-right: 10px;
	}

	dt, dd {
		display: inline-block;
		vertical-align: middle;
	}

	&:first-child {
		@include span-columns(1.5);
	}

}

.payment {

	img {
		margin-bottom: 0;
	}

}