%item {
    @include omega(4n);
    float: left;
    display: block;
    width: 25%;
    position: relative;
    margin-bottom: 20px;
    padding: 20px 0 70px 0;

    .content {
        width: 150px;
        margin: 0 auto;
        text-align: center;
    }

}

%item-hover {
    @include box-shadow($y:0px, $blur:15px, $color: rgba(color('black'), 0.1));

    .show-hover {
        @include fade(in);
    }

}

.products-grid {
    @include span-columns(12);
    margin-right: 0;

    .list-product--bundle {

        .price-box {

            .price-from {

                display: none;
            }
        }
    }

    .item {
        @extend %item;
        @import "../components/list/flags.scss";

        &.logged-in {

            &:hover {
                @extend %item-hover;
            }

        }

        .show-hover {
            @include fade();
        }

        .product-image {
            margin-bottom: 10px;
            display: block;

            .hidden {
                @extend .hide;
            }

            img {
                margin: 0 auto;
            }

        }

        .products-info {
            line-height: 20px;

            .name {
                min-height: 40px;
            }

            .author {
                min-height: 40px;
                display: block;
            }

        }

        .price-box {
            margin-top: 15px;
            @import "../components/list/price.scss";
        }

        .installment {
            margin-top: 5px;
        }

        .actions {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            @import "../components/list/add-to-cart.scss";
        }

    }

}
