span {
	font-size: 30px;
	margin-right: 10px;
	padding-top: 12px;
}

span, .hello {
	display: inline-block;
	vertical-align: top;
}

.hello {
    padding-top: 8px;
    position: relative;
    line-height: 20px;
    width: 56%;
    padding-bottom: 24px;
    margin-bottom: -24px;

	b {
		display: block;
		font-weight: normal;
	}

	span {
		position: absolute;
		right: 0;
		bottom: 40px;
		font-size: 14px;
		padding: 0;
	    -webkit-transition: all 0.2s;
	    transition: all 0.2s;		
	}

	&:hover {

		span {
			transform: rotate(180deg);
		}

		& > .box {
			@include fade($type: in);
		}

	}

}

.box {
	@include arrow();
	@include box-shadow();
	@include fade();
	width: 175px;
	border-top: 1px solid color('yellow');
	color: color('gray', 'dark');
	padding: 20px;
	position: absolute;
	left: -10px;
	z-index: 10;
	margin-top: 10px;
	background: color('white');

	&:hover {
		@include fade($type: in);
	}

	a {
		display: block;
		margin: 13px 0;
		line-height: 24px;
		padding-left: 20px;

		&:last-child {
			margin-bottom: 0;
		}

		&.button {
			margin: 0;
		}

	}

}

&.not-logged {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	line-height: 0;
	padding-top: 12px;
	background: color('blue', 'light');

	.icon-user, p {
		display: inline-block;
		vertical-align: middle;
	}

	.icon-user {
		padding-left: 20px;
	    position: relative;
	    top: 5px;		
	}

	p {
		font-size: 16px;
		text-transform: uppercase;
	}

	a {
		display: block;
		color: color('white');
		padding-left: 84px;
	}

}