li {
	display: inline-block;
	vertical-align: middle;
	padding: 10px 20px;
	border-right: 1px solid color('blue', 'light');

	&:last-child {
		border-right: 0;
	}

	a {
		font-size: 16px;
		text-transform: uppercase;
		color: color('white');
	}

}