.input-box {
	position: relative;
	margin-bottom: 0;

	input {
		padding: 15px 20px;
	}

	button {
		position: absolute;
		right: 6px;
		top: 6px;
		padding: 0;
		background: color('yellow');
		height: 40px;
		width: 40px;
	}

}