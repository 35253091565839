h1 {
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
}

h2 {
    font-size: 16px;
}

.page-title {
    @include span-columns(12);

    h1, h2 {
        padding-bottom: 10px;
    }

}
