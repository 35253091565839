.catalogsearch-result-index {
	
	.block-layered-nav {
		@import "components/category/layered.scss";
	}

	.page-title {
		margin: 0 auto 20px auto;
		font-size: 20px;
		padding: 10px 30px 20px 30px;
		border-bottom: 4px solid color('gray', 'light');

		h1 {
			font-size: 20px;
			font-weight: normal;
			text-transform: initial;

			strong {
				padding-left: 5px;
				font-weight: bold;
				text-transform: uppercase;
				color: color('blue');
			}

		}

	}

	.toolbar {
		@include span-columns(12);
		@import "components/category/toolbar.scss";
	}

	&.catalogsearch-result-index-empty {
		
		.breadcrumbs {
			@include outer-container();
			float: none;
			padding: 22px $gapp;
		}

		.main-container {
	    	@include outer-container(100%);
	    	
	    	.container, .main {
	    		@include span-columns(12);
	    		padding: 0;
	    	}

	    	.align-container {
	    		@include outer-container();
	    		padding: 0 $gapp;
	    	}

	    }

	    .page-title {
			float: none;
			display: block;
			width: 80%;
	    }

	    .box {
	    	@include span-columns(12);
			padding: 50px 0 20px 0;

	    	&.gray {
				background: color('gray', 'light');
	    	}

			.tabs-home {
				@include span-columns(12);
				position: relative;

				.tabs-block {
					padding: 0 0 15px 0;
					text-transform: uppercase;
					color: color('blue', 'light');
					border-bottom: 1px solid rgba(color('gray'), 0.2);

					h2 {
						font-size: 24px;					
					}

				}
		
				.loader {
					margin: 20px auto;
				}

			}

	    }

	}

}