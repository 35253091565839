.content-home {
	@include span-columns(12);

	.tabs-home {
		@include outer-container;
		padding: 0 $gapp;
		position: relative;
		text-align: center;

		.tabs-block {
			@include span-columns(12);
			margin: 40px 0 0 0;
			padding: 15px 0;
			text-transform: uppercase;
			color: color('blue', 'light');

			h2 {
				font-size: 24px;					
			}

			li {
				@include span-columns(12);
				position: relative;
			}

		}

		.container-tabs {
			@include span-columns(12);
			padding: 20px 0 0 0;

			.content-tab {
				@include span-columns(12);
			}
			
		}

		.loader {
			margin-bottom: 20px;
		}

	}

}
