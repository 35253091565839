%button-style {
    font-family: $font;
    font-weight: bold;
    padding: 13px 40px;
    letter-spacing: 1px;
    font-size: 20px;
    width: 100%;
    display: block;
    text-align: center;
    text-transform: uppercase;
    border: 0;
    color: color('white');
    background: color('green');
    cursor: pointer;
    @include transition(background, 0.2s);

    &:hover {
		background: darken(color('green'), 5);
    }

    &.small {
    	font-size: 16px;
    	text-transform: initial;
    	padding: 10px 20px;
    }
    
}

%gray {
    color: color('gray');
    background: color('gray', 'light');
}

%gray-hover {
	background: darken(color('gray', 'light'), 5);
}

%gray-dark {
    color: color('white');
    background: color('gray', 'semi-dark');
}

%gray-dark-hover {
    background: darken(color('gray', 'semi-dark'), 5);
}

%yellow {
    color: color('blue');
    background: color('yellow');
}

%yellow-hover {
	background: darken(color('yellow'), 5);
}

%white {
    color: color('blue');
    background: color('white');
}

%white-hover {
    background: darken(color('white'), 5);
}

%blue {
    color: color('white');
    background: color('blue');
}

%blue-hover {
    background: darken(color('blue'), 5);
}

%black {
    color: color('white');
    background: color('black');
}

%black-hover {
    background: lighten(color('black'), 15);
}

button,
.button {
    @extend %button-style;

    &.gray {
        @extend %gray;

        &:hover {
            @extend %gray-hover;
        }

    }

    &.gray-dark {
        @extend %gray-dark;

        &:hover {
            @extend %gray-dark-hover;
        }

    }    

    &.yellow {
        @extend %yellow;

        &:hover {
            @extend %yellow-hover;
        }

    } 

    &.white {
        @extend %white;

        &:hover {
            @extend %white-hover;
        }

    }

    &.blue {
        @extend %blue;

        &:hover {
            @extend %blue-hover;
        }

    }  

    &.black {
        @extend %black;

        &:hover {
            @extend %black-hover;
        }

    }               

}