*,
*:before,
*:after {
    margin: 0;
    padding: 0;
}

*:focus {
    outline: 0;
}

html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
}

body {
    background: #FFFFFF;
    color: #000000;
    line-height: 1;
}

html,
body,
img,
fieldset,
abbr,
acronym {
    border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

th,
code,
cite,
caption {
    font-weight: normal;
    font-style: normal;
    text-align: left;
}

address {
    font-style: normal;
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

img {
    display: block;
}

ol,
ul {
    list-style: none;
}

q:before,
q:after {
    content: '';
}

input:focus,
input[type="search"]:focus {
    outline-offset: -2px;
}

input[type="search"] {
    -webkit-appearance: none; /* 1 */
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; /* 2 */
    box-sizing: border-box;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

object[type='application/gas-events-uni']{
    display: none;
}