%box-cart {
	background: color('gray', 'light');
	padding: 20px;

	h2 {
		font-size: 16px;
		text-transform: uppercase;
		margin-bottom: 10px;
		line-height: 30px;

		span {
			margin-right: 5px;
			color: color('blue');
		}

	}

	input {
		@include span-columns(8);
		font-style: italic;
	}

	button {
		@include span-columns(4);
		float: right;
		margin-right: 0;
		font-size: 12px;
		text-transform: uppercase;
		padding: 16px 20px;	
	}

	.validation-advice {
		float: left;
		width: 100%;
		color: color('red');
		text-transform: uppercase;
		font-size: 12px;
		margin: 10px 0;
	}

} 

.box-shipping {
	@extend %box-cart;
	@include span-columns(4.5);

	.sp-methods {
		@include span-columns(12);
		font-size: 16px;
		
		li {
			display: block;
			margin: 20px 0;

			input {
				display: none;
			}

			&:last-child {
				margin-bottom: 0;
			}

		}

	}

	.shipping-postcode {

		input {
			@include span-columns(5.5);
			float: right;
			margin-right: 0;
		}

		button {
			@include span-columns(3);
			float: right;
			margin-left: 2%;
			margin-right: 0;
			padding: 16px 0;
		}

		a {
			@include span-columns(3.5);
			float: right;
			text-decoration: underline;
			line-height: 47px;
			text-align: right;
		}

	}

}

.box-coupon {
	@extend %box-cart;
	@include span-columns(3.5);	
}

.cart-totals-wrapper {
	@extend %box-cart;
	@include span-columns(4);
	margin-right: 0;
	float: right;
	font-size: 18px;

	td {
		padding-bottom: 20px;
		line-height: 30px;

		&:last-child {
			text-align: right;
		}

	}

	tfoot {

		td {
			border-top: 1px solid rgba(color('gray'), 0.2);
			padding-top: 30px;
		}

	}

}