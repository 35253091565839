.customer-account-forgotpassword,
.customer-account-changeforgotten {

	.page-title {
		@include span-columns(12);
		margin: 0 0 30px 0;
		text-align: center;

		h1 {
			color: color('blue');
			padding-bottom: 5px;
		}

		p {
			font-size: 16px;
		}

	}

	.main {
		margin: 40px 0 120px 0;

		.col-main {
			width: 40%;
			float: none;
			margin: 0 auto;
		}

	}

}