.page-header {
	background: color('blue');
	color: color('white');
	
	.page-header-container {
		@include outer-container;
		padding: 16px 0 20px 0;
		position: relative;

		.header-logo {
			@include span-columns(2);
		}

		.header-search {
			@include span-columns(6.5);
			padding-top: 4px;
			@import "components/header/search.scss";
		}

		.header-links {
			@include span-columns(6.5);
			padding-top: 12px;
			@import "components/header/links.scss";
		}		

		.header-account {
			@include span-columns(3);
			padding-top: 4px;
			@import "components/header/account.scss";
		}		

		.header-cart {
			@include span-columns(1);
			padding-top: 4px;
			@import "components/header/cart.scss";
		}									

	}

	.header-nav {
		@include span-columns(12);
		@include box-shadow();		
		background: color('white');
		border-bottom: 1px solid color('blue');
		@import "components/header/nav.scss";
	}

	.header-secure {
		@include span-columns(2);
		float: right;
		font-size: 14px;
		text-transform: uppercase;
		text-align: right;
		padding: 8px 0;

		span, p {
			@include span-columns(6);
		}

		span {
			font-size: 30px;
			line-height: 34px;
		}

		p {
			text-align: left;
			line-height: 18px;
			padding-left: 10px;
		}

	}

}