.ratings {

	.rating-box,
	.amount {
		display: inline-block;
		vertical-align: middle;
	}

	.amount {
		margin-left: 10px;
		font-size: 16px;
	}

}

.rating-box {
    width: 150px;
    height: 21px;
    background: url(../images/rating-empty.png) 0 0 repeat-x;

    .rating {
        background: url(../images/rating.png) 0 0 repeat-x;
        height: 21px;
        display: block;
    }

}

.no-rating {
	font-size: 16px;
}

.form-add-review {
	@include span-columns(12);
	margin-top: 20px;
	display: none;
	padding: 30px;

	table {
		margin-bottom: 20px;

		thead {

			th {
				padding: 20px 0;
				text-align: center;
			}

		}

		tbody {

			th, td {
				padding: 15px 0;
				font-size: 16px;
			}

			td {
				text-align: center;				
			}

		}

	}

	.validation-advice {
		font-size: 12px;
		color: color('red');
		text-transform: uppercase;
		text-align: left;
	}

	.form-list {
		margin-top: 20px;

		em {
			@extend .no-display;
		}

	}

	.buttons-set {
		@include span-columns(12);
		margin-top: 20px;
		text-align: center;

		button {
			font-size: 16px;
			display: block;
			width: 30%;
			margin: 0 auto;
			text-transform: uppercase;
		}	
				
	}

}