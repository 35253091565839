dl {
	@include span-columns(3);

	&:last-child {
		float: right;
		clear: right;
		margin-top: 40px;
	}

	&:nth-child(4) {
		margin-right: 0;
	}

	dt {
		text-transform: uppercase;
		font-size: 18px;
		margin-bottom: 30px;
	}

	dd {
		color: color('white');

		a {
			color: color('white');
			display: block;
			font-size: 14px;
			margin-bottom: 12px;
		}

	}

	&.social {

		dt {
			margin-bottom: 20px;
		}
		
		a {
			font-size: 34px;
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
		}

	}

}

a {

	&:hover {
		color: rgba(color('white'), 0.6);
	}

}