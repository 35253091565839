.bar {
	@include span-columns(12);
	padding: 12px 0;
	background: color('gray', 'light');
	text-align: center;
	border-bottom: 1px solid rgba(color('gray'), 0.2);

	li {
		display: inline-block;
		vertical-align: middle;
		padding: 10px 40px;
		text-transform: uppercase;
		border-right: 1px solid rgba(color('gray'), 0.2);

		&:last-child {
			border-right: 0;
		}

		span {
			color: color('blue', 'light');
			margin-right: 20px;
			font-size: 24px;
			vertical-align: middle;
		}

	}

}

.bar-notlogin {
	@include span-columns(12);
	text-align: center;

	li {
		@include span-columns(3, block-collapse);
		padding: 10px 40px;
		line-height: 20px;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			background: rgba(color('gray'), 0.2);
			height: 500px;
			width: 1px;
		}

		&:last-child {
			
			&:after {
				@extend .no-display;
			}

		}

		strong {
			display: block;
			padding-top: 40px;
			font-size: 22px;
			font-weight: normal;
			text-transform: uppercase;
			color: color('blue');
		}

		img {
			display: block;
			margin: 30px auto 10px auto;
		}

		a {
			margin: 50px 0;
			font-size: 16px;
			font-weight: normal;

			&:hover {
				color: color('white');
			}

		}

	}

}