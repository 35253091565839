.address-book {
	@include span-columns(12);
	margin: 0 0 150px 0;	
	font-size: 16px;
	line-height: 20px;

	.col-1 {
		padding-right: 40px;
		border-right: 2px solid color('blue');	
	}

	.col-2 {
		padding-left: 20px;
	}

	h2 {
		padding-top: 0;
	}

	h3 {
		@include span-columns(8);
		padding-bottom: 20px;
	}

	p {
		@include span-columns(4);
		margin-right: 0;

		a {
			font-weight: bold;
			text-decoration: underline;
		}

	}

	address {
		@include span-columns(12);
	}

	ul {
		@include span-columns(12);

		li {
			@include span-columns(12);
			margin: 0 0 60px 0;
		}

		.change-address {
			@include span-columns(12);
			margin: 30px 0 0 0;

			a {
				padding-right: 10px;
				font-weight: bold;
				text-decoration: underline;					
			}

		}

		.button {
			font-size: 16px;
			font-weight: normal;
			text-transform: uppercase;
		}

	}

	.empty {

		p {
			@include span-columns(12);
		}
		
	}

}

&.customer-address-form {

	.content  {
		margin-bottom: 20px;

		h2 {
			@include span-columns(12);
		}

		.buttons-set {
			@include span-columns(12);
			margin: 20px 0 0 0;

			.button {
				@include span-columns(4);
			}

		}

		.control {
			@include span-columns(12);
			margin: 10px 0 0 0;

			label {
				margin-bottom: 10px;
			}
			
			p {
				font-size: 16px;
			}

		}

	}

}