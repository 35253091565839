.catalog-product-view {

	.main-container {
    	@include outer-container(100%);
    	
    	.container, .main {
    		@include span-columns(12);
    		padding: 0;
    	}

    }

	.block-title {
		position: relative;
		border-bottom: 1px solid rgba(color('gray'), 0.6);
		padding-bottom: 15px;

		h2 {
			font-size: 24px;
			color: color('blue', 'light');
			text-transform: uppercase;
		}

	}

	.product-view {
		@include outer-container();
		margin: 20px auto;

		.product-img {
			@include span-columns(5.7);
			@import "components/product/sharing.scss";
			@import "components/product/media.scss";
		}

		.product-essential {
			@include span-columns(6.3);
			@import "components/product/essential.scss";
			float: right;
			margin-right: 0;
		}

		.product-tabs {
			@include span-columns(12);
			@import "components/product/tabs.scss";
		}

	}

	.signature {
		@include span-columns(12);
		margin: 80px 0 30px 0;
		text-align: center;
		@import "components/product/signature.scss";
	}

	.product-related {
		@include outer-container();
		position: relative;
	}

	.buy-together {
		background: color('gray', 'light');
		@import "components/product/buytogether.scss";
	}

}